<template>
  <div id="procedure-item-table">
    <table class="procedures-table">
      <thead>
        <tr>
          <b-th>Tipo</b-th>
          <b-th>Item</b-th>
          <b-th>Convênio</b-th>
          <b-th>Quant.</b-th>
          <b-th>Valor Unit.</b-th>
          <b-th>Desconto</b-th>
          <b-th colspan="2">Valor Total</b-th>
          <b-th></b-th>
        </tr>
      </thead>
      <tbody>
        <b-tr v-for="(item, idx) in value.items" :key="idx">
          <b-td>
            <ItemIcon :item="item" />
          </b-td>
          <b-td>{{ item.name }}</b-td>
          <b-td>{{ item.health_plan_name }}</b-td>
          <b-td>{{ item.quantity }}</b-td>
          <b-td>{{ parseNumberToMoney(item.unit_price) }}</b-td>
          <b-td>{{ item | formatDiscount }}</b-td>
          <b-td colspan="2">{{ item.final_price | parseNumberToMoney }}</b-td>

          <b-td class="actions" colspan="2">
            <Icon tooltip="Editar Item">
              <Edit class="action-icon blue-icon" @click="editItem(idx)" />
            </Icon>
            <Icon tooltip="Remover Item">
              <Trash class="action-icon red-icon" @click="removeItem(idx)" />
            </Icon>
          </b-td>
        </b-tr>
      </tbody>
      <tfoot>
        <b-tr>
          <b-td colspan="5">Valor Total</b-td>
          <b-td></b-td>
          <b-td colspan="3">{{budgetAmount}}</b-td>
        </b-tr>
      </tfoot>
    </table>
    <ItemEditModalForm v-model="editingItem" />
  </div>
</template>
<script>
import {parseNumberToMoney} from "@/utils/moneyHelper"
import {discountFormats, item} from "@sp/config/item"
import ItemEditModalForm from "@/modules/SellingPanel/components/ItemEditModalForm.vue";

export default {
  name: "ProcedureItemTable",
  components: {
    ItemEditModalForm,
    Trash: () => import('@/assets/icons/trash.svg'),
    Icon: () => import('@/components/General/Icon'),
    Edit: () => import('@/assets/icons/edit.svg'),
    ItemIcon: () => import('@sp/components/Items/ItemIcon.vue'),
  },
  props: {
    value: {
      type: Object,
    },
  },

  computed: {
    items() {
      return this.value.items.length
    },

    budgetAmount() {
      return parseNumberToMoney(Math.fround( this.value.items.reduce((acc, i) => acc + parseFloat(i.final_price), 0) ).toFixed(2))
    }
  },

  data() {
    return {
      editingItem: {...item}
    }
  },
  methods: {
    parseNumberToMoney,

    openEditModal() {
      this.$bvModal.show('item_edit_modal_form')
    },
    // changing methods
    editItem(idx) {
      this.editingItem = null
      this.editingItem = this.value.items[idx]
      this.openEditModal()
    },

    removeItem(idx) {
      const itemToBeRemoved = this.value.items[idx]

      if(itemToBeRemoved?.id) {
        const l = this.$loading.show()
        this.api.deleteBudgetItem(itemToBeRemoved.id).then(res => {
          this.removeFromList(itemToBeRemoved)
        })
        .catch(error => {
          console.error(error)
          this.$toast.error(`Não foi possível excluir o Item da Lista.`)
        })
        .finally(() => l.hide() )
      }
      else {
        this.removeFromList(itemToBeRemoved)
      }
    },

    removeFromList(item) {
      this.value.items = this.value.items.filter(it => it.item_id !== item.item_id)
      this.$toast.success(`Item Excluído com Sucesso`)
    },
    // helper methods
    getFormat(item, value) {
      if(item.discount_format === discountFormats.get('currency').value) {
        return parseNumberToMoney(value)
      }

      return value+'%'
    }
  },

  filters: {
    formatDiscount(item) {
      if(item.discount_format === discountFormats.get('currency').value) {
        return parseNumberToMoney(item.discount)
      }
      return item.discount+'%'
    },
    parseNumberToMoney
  }
}
</script>

<style lang="scss">
#procedure-item-table {
  width: 100%;
  max-height: 80vh;
  overflow: hidden;

  .procedures-table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    th {
      position: sticky;
      top: 0;
    }
    thead {
      border-bottom: 1px dotted var(--neutral-300);
    }
    thead, tfoot{
      background-color: var(--neutral-100);
    }
    th, td {
      overflow-x: hidden;
      z-index: 10;
      min-width: 180px !important;
      padding: 9px  0 9px 6px !important;
    }

    td.actions {
      display: inline-flex;
      .icon-container {
        margin: 0 3px;
      }
    }
    tbody, thead {
      tr{
        td, th {
          width: 7%;
        }
        td, th:nth-child(1){
          width: 3%;
        }
        td, th:nth-child(2){
          width: 30%;
        }
        td, th:nth-child(3){
          width: 15%;
        }
        td, th:nth-child(4){
          width: 5%;
        }

      }
    }

    .action-icon {
      cursor: pointer;
      width: 25px;
      &.red-icon {
        stroke: var(--pink);
      }

      &.blue-icon {
        stroke: var(--blue-500);
      }
    }


    tfoot tr{
      display: contents;
      td {
        padding: 10px 0 10px 5px !important;
        font-weight: bold;
      }
    }

    .align-start {
      text-align: start
    }
  }
}
</style>